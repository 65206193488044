<template>
	<div class="page">
		<Tables ref="xTable" :tableName="tableName" :tableData="List" :totalPage="totalPage" :isLoading="isLoading"
			:searchFormData="searchFormData" :showRefresh="true" :showSearch="true"
			:showAdd="$hasAccess('hlht.manager/detailedit')" addName="新增" @getList="getList" @clearSearch="clearSearch"
			@addItemInTable="addOne">

			<!--搜索自定义内容-->
			<el-form-item slot="search-item">
				<el-input v-model="searchFormData.group_name" size="small" clearable placeholder="站点名称"></el-input>
			</el-form-item>
			<el-form-item slot="search-item">
				<el-select v-model="searchFormData.status" size="small" clearable placeholder="状态" style="width:100px">
					<el-option value="-1">全部</el-option>
					<el-option value="1">启用</el-option>
					<el-option value="0">停用</el-option>
				</el-select>
			</el-form-item>
			<!--表格内容-->
			<vxe-table-column slot="table-item" field="group_name" title="场站名称" />
			<vxe-table-column slot="table-item" field="device_nos" title="通讯编号" />
			<vxe-table-column slot="table-item" field="opentime" title="推送时间" />
			<vxe-table-column slot="table-item" field="status" title="状态">
				<template v-slot="{ row }">
					<span :style="{color:row.statusValue == '启用'?'green':''}">{{ row.statusValue }}</span>
				</template>
			</vxe-table-column>	
			<vxe-table-column slot="table-item" field="ctime" title="创建时间" />
			<vxe-table-column slot="table-item" field="stoptime" title="停止时间" />
			<vxe-table-column slot="table-item" title="操作">
				<template v-slot="{ row }">
					<el-button size="small" plain @click="detail(row)">编辑</el-button>
					<el-button size="small" plain @click="open(row)">{{ row.statusText }}</el-button>
				</template>
			</vxe-table-column>
		</Tables>

		<!-- 编辑 -->
		<el-dialog title="站点选择" :visible.sync="editDialogShow" :close-on-click-modal="false" :close-on-press-escape="false" width="600px">
			<el-form :model="editDialogFormData" :rules="rules" ref="editForm" label-width="100px" class="form">
				<div v-if="editStep == 0">
					<el-form-item label="推送时间">
						<el-radio v-model="editDialogFormData.openType" :label="0">即时推送</el-radio><br />
						<el-radio v-model="editDialogFormData.openType" :label="1">定时推送</el-radio>
						<el-date-picker :disabled="editDialogFormData.openType == 0" v-model="editDialogFormData.opentime" value-format="yyyy-MM-dd HH:mm:ss" size="small" type="datetime" placeholder="选择日期时间"></el-date-picker>
					</el-form-item>
					<el-input v-model="edit_group_name" placeholder="筛选场站" @input="changeShowGroup()"></el-input>
					<el-table ref="multipleTable" :data="FilterGroupList" @selection-change="tableSelectChange" height="300">
						<el-table-column type="selection" width="55"></el-table-column>
						<el-table-column prop="city" label="城市"></el-table-column>
						<el-table-column prop="group_name" label="电站名称"></el-table-column>
					</el-table>
					<div slot="footer" class="dialog-footer">
						<el-button type="primary" size="small" plain icon="el-icon-check" @click="next()">下一步</el-button>
						<el-button size="small" plain icon="el-icon-close" @click="closeDialog">取 消</el-button>
					</div>
				</div>
				<div v-if="editStep == 1">
					<el-table ref="multipleTable" :data="DeviceList" @selection-change="deviceSelectChange" height="300">
						<el-table-column type="selection" width="55"></el-table-column>
						<el-table-column prop="device_num" label="通讯编号" width="280"></el-table-column>
						<el-table-column prop="qk_type" label="设备类型">
							<template slot-scope="scope">
								{{ scope.row.qk_type==10?"慢充":"快充" }}
							</template>
						</el-table-column>
					</el-table>
					<div slot="footer" class="dialog-footer">
						<el-button type="primary" size="small" plain icon="el-icon-check" @click="saveEdit()">保 存</el-button>
						<el-button size="small" plain icon="el-icon-close" @click="closeDialog">取 消</el-button>
					</div>
				</div>
			</el-form>
		</el-dialog>
		<!-- 站点停用 -->
		<el-dialog title="站点停用" :visible.sync="openShow" :close-on-click-modal="false" :close-on-press-escape="false" width="400px">
			<el-radio v-model="editDialogFormData.status" :label="0">即时停用</el-radio><br />
			<el-radio v-model="editDialogFormData.status" :label="1">定时停用</el-radio>
			<el-date-picker v-model="editDialogFormData.stoptime" value-format="yyyy-MM-dd HH:mm:ss" size="small" type="datetime" placeholder="选择日期时间"></el-date-picker>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" size="small" plain icon="el-icon-check" @click="saveStop()">保 存
				</el-button>
				<el-button size="small" plain icon="el-icon-close" @click="closeDialog">取 消
				</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import Tables from '@/components/tables'
	export default {
		name: 'market-activitycommon-list',
		components: {
			Tables
		},
		data() {
			return {
				// 表格信息
				tableName: '站点推送详情',
				isLoading: false,
				totalPage: 0,
				searchFormData: {},
				OperatorID: "", //组织代码
				List: [], //推送的组织列表
				GroupList: [], //场站列表
				DeviceList: [], //设备列表
				FilterGroupList: [], //筛选场站列表
				rules: {
					OperatorID: [{
						required: true,
						message: '请选择企业',
						trigger: 'change'
					}]
				},
				// 编辑
				editDialogShow: false,
				editStep: 0,
				edit_group_name: '',
				editDialogFormData: {},
				openShow: false,
			}
		},
		computed: {
		},
		mounted() {
			this.OperatorID = this.$route.query.id;
			this.getList();
			//获取优惠券列表
			const params = {
				token: this.$store.state.user.token,
				page: 1,
				size: 1000,
				getcity: 1,
			};
			this.$api.Device.GetGroupMinList(params).then((res)=>{
				this.GroupList = res;
				//this.FilterGroupList = res.slice(0, 5);
				this.FilterGroupList = res;
			});

		},
		activated() {},
		methods: {
			// 表格列表
			async getList() {
				let params = {
					token: this.$store.state.user.token,
					OperatorID: this.OperatorID,
					page: 1,
					size: 1000
				}
				params = Object.assign(params, this.searchFormData);
				const res = await this.$api.Hlht.Manager.detail(params, {});
				this.List = res.data;
			},
			// 重置搜索
			clearSearch() {
				this.searchFormData = {}
			},
			// 重置form
			resetFormData() {
				this.editDialogFormData = {status:1,openType:0,opentime:null,stoptime:null}
				if (this.$refs.editForm) {
					this.$refs.editForm.resetFields()
				}
			},
			// 新增
			addOne() {
				this.editStep = 0;
				this.resetFormData()
				this.editDialogShow = true
			},
			// 启用 停止
			open(row) {
				this.editDialogFormData = row;
				if(row.statusText == "启用"){
					this.editDialogFormData.stoptime = null;
					this.editDialogFormData.status = 1;
					this.saveStop();
				}else{
					this.openShow = true;
				}
			},
			next(){ //下一步
				if(this.editDialogFormData.group_id > 0){
					this.editStep++;
					this.$api.Device.GroupDeviceNo({token: this.$store.state.user.token, group_id: this.editDialogFormData.group_id}).then((res)=>{
						let ds = this.editDialogFormData.device_nos.slice(0);
						this.DeviceList = res;
						this.$nextTick(()=>{
							for(let i=0;i<this.DeviceList.length;i++){
								if(ds.indexOf(this.DeviceList[i].device_num) != -1){
									this.$refs.multipleTable.toggleRowSelection(this.DeviceList[i], true);
								}
							}
						})
						//console.log(this.DeviceList);
					});
				}else{
					this.$notify({
						message: '先选择一个站点',
						type: 'warning'
					});
				}
			},
			changeShowGroup(){ //筛选场站显示
				this.FilterGroupList = this.GroupList.filter((v)=>{
					if(v.city.indexOf(this.edit_group_name) >= 0) return v;
					if(v.group_name.indexOf(this.edit_group_name) >= 0) return v;
				});
				// if(this.FilterGroupList.length > 5){
				// 	this.FilterGroupList.length = 5;
				// }
			},
			tableSelectChange(val){ //选择场站变化
				if(val.length > 0){
					this.editDialogFormData.group_id = val[val.length-1].group_id;
				}else{
					this.editDialogFormData.group_id = 0;
				}
				if(val.length > 1){
					for(let i=0;i<val.length-1;i++){
						this.$refs.multipleTable.toggleRowSelection(val[i]);
					}
				}
			},			
			deviceSelectChange(val){ //选择设备变化
				//console.log("deviceSelectChange", val);
				this.editDialogFormData.device_nos = [];
				for(let i=0;i<val.length;i++){
					this.editDialogFormData.device_nos.push(val[i].device_num);
				}
			},
			async saveStop(){
				let params = Object.assign({token: this.$store.state.user.token}, this.editDialogFormData);
				await this.$api.Hlht.Manager.detailedit(params);
				this.$notify({
					title: '成功',
					message: '操作成功',
					type: 'success'
				});
				this.closeDialog()
				this.$refs.xTable.refreshTable()
			},
			// 关闭弹窗
			closeDialog() {
				this.editDialogShow = false;
				this.openShow = false;
			},
			// 返券记录
			detail(row) {
				this.editStep = 0;
				this.editDialogShow = true;
				this.editDialogFormData = row;
				this.next();
			},
			saveEdit() { // 编辑保存
				this.$refs.editForm.validate(async valid => {
					if (valid) {
						let params = Object.assign({token: this.$store.state.user.token, OperatorID: this.OperatorID}, this.editDialogFormData);
						await this.$api.Hlht.Manager.detailedit(params);
						this.$notify({
							title: '成功',
							message: '操作成功',
							type: 'success'
						})
						this.closeDialog()
						this.$refs.xTable.refreshTable()
					}
				})
			},
		}
	}
</script>

<style lang="scss" scoped>
</style>
